<template>
  <div id="HeaderContainer">
    <div class="container">
      <div class="leftSection ">
        <img
            :src="window.Routes.getImage(shop.logo, 'preview')"
            class="profileImage"
        />
        <div class="nameSection">
          <div class="rateSection font-size-big">
            <img src="@/assets/rate.svg" class="rateIcon"/>
            {{ shop.rating }}
            <i class="fas fa-circle mx-2 color-secondary"></i>
            <div class="distance font-size-md">
              {{ shop.distance && shop.distance.toFixed(1) }}
              {{ $t("global.units.km") }}
            </div>
          </div>
          <div class="shopName font-size-xl">{{ shop.name }}</div>
          <div
              v-if="access_token !== null"
              class="subscribeButton font-size-md"
              @click="toggleSubscribe(shop.id)"
              :class="{ subscribed: subscribed }"
          >
            {{ subscribed ? $t("shops.subscribed") : $t("shops.subscribe") }}
            <img src="@/assets/bell-inactive.svg"/>
          </div>
        </div>
      </div>

      <div class="rightSection row">
        <div class="col-lg-12 my-0">
          <div class="row my-0">
            <div class="col-lg-5 my-0 col-sm-12">
              <div class="lineBorder">
                <div class="row my-0">
                  <!-- Opened-->

                  <div class="col-12  openedInfo">
                    <div
                        class="badge font-size-sm"
                        :class="{
                        'bg-lightYellow color-twiks': shop.opened,
                        'bg-danger color-white': !shop.opened
                      }"
                    >
                      {{
                        shop.opened ? $t("global.open") : $t("global.closed")
                      }}
                    </div>

                    <!-- Open -->

                    <span
                        class="ms-2 fontStyle"
                        v-if="shop.opened && shop.closes_at !== null"
                    >
                      {{
                        $t("shops.closes_at") +
                        ": " +
                        moment(shop.closes_at)
                            .local()
                            .format("ddd LT")
                      }}
                    </span>

                    <!-- Closed -->

                    <span
                        class="ms-2 fontStyle"
                        v-if="!shop.opened && shop.opens_at !== null"
                    >
                      {{
                        $t("shops.opens_at") +
                        ": " +
                        moment(shop.opens_at)
                            .local()
                            .format("ddd LT")
                      }}
                    </span>
                  </div>

                  <!-- Location -->

                  <div
                      class="col-12 my-lg-2  fontStyle locationInfo"
                      v-if="shop.city || shop.address_description"
                  >
                    <i class="fas fa-map-marker-alt "></i>
                    {{ shop.city.name }},
                    {{ shop.address_description }}
                  </div>

                  <!--Payment Methods-->

                  <div
                      class="payment_methods col-12 my-0 paymentInfo"
                      v-if="shop"
                  >
                    <div class="row">
                      <div class="col-auto col-md-12 row">
                        <div
                            class="col-auto Aligner items-left"
                            v-if="
                            shop.payment_gateway === 'both' ||
                              shop.payment_gateway === 'bank'
                          "
                        >
                          <img
                              class="icon pe-1"
                              :src="
                              require(`@/assets/payment_methods/bank_black.svg`)
                            "
                          />
                          {{ $t("shops.payment_methods.bank") }}
                        </div>
                        <div
                            class="col-auto Aligner items-left"
                            v-if="
                            shop.payment_gateway === 'both' ||
                              shop.payment_gateway === 'cash'
                          "
                        >
                          <img
                              class="icon pe-1"
                              :src="
                              require(`@/assets/payment_methods/cash_black.svg`)
                            "
                          />
                          {{ $t("shops.payment_methods.cash") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Info-->

            <div class="col-lg-7 col-sm-12 my-0">
              <div class="row description" v-if="shop !== null">
                <div
                    class="col-12 font-weight-bold font-size-md color-secondary"
                >
                  {{ $t("shops.info") }}
                </div>
                <div class="col-12 color-secondary pt-2 shopDescription">
                  <div id="truncateShopDescription">
                    {{ shop.description }}
                  </div>
                  <a
                      href="#"
                      class="font-size-sm"
                      @click.prevent="toggleTruncate"
                  >{{
                      isTruncatedHeader
                          ? $t("global.show_more")
                          : $t("global.show_less")
                    }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12  my-0 ">
          <div class="bottomRow">
            <div class="row">
              <div class="col-lg-12 orderInfo">
                <div class="titleBox font-size-md">
                  {{ $t("shops.minimum_order") }}
                </div>
                <div class="valueBox font-size-md color-danger">
                  {{ shop.minimum_buyings }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dotdotdot from "dotdotdot-js";
import {mapGetters} from "vuex";

export default {
  name: "HeaderShopView",

  props: {
    shop: {
      type: Object,
      default: null
    },
    subscribed: {
      type: Boolean
    }
  },
  data() {
    return {
      truncateHeaderEl: null,
      truncatedHeader: null,
      isTruncatedHeader: false
    };
  },
  computed: {
    ...mapGetters(['access_token'])
  },
  methods: {
    toggleSubscribe() {
      this.$emit("toggleSubscribe");
    },

    //Initialize dotdotdot
    initDotdotdot() {
      this.truncateHeaderEl = document.getElementById(
          "truncateShopDescription"
      );
      this.truncateHeaderEl.classList.add("dotdotdot-ready");

      this.truncatedHeader = new Dotdotdot(this.truncateHeaderEl, {
        callback: isTruncated => {
          if (isTruncated === true) {
            this.isTruncatedHeader = true;
          }
        },
        height: 60
      });
    },
    //Toggle truncate
    toggleTruncate() {
      if (this.isTruncatedHeader === true) {
        this.truncatedHeader.API.restore();
        this.isTruncatedHeader = false;
      } else {
        this.truncatedHeader.API.truncate();
        this.isTruncatedHeader = true;
      }
    }
  },
  watch: {
    shop: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.initDotdotdot();
        }, 200);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#HeaderContainer {
  width: 100%;
  padding: 45px 0;
  background-color: map-get($colors, "lightGray");

  .container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .leftSection {
      align-items: center;
      width: 50%;
      display: flex;
      flex-direction: row;

      .profileImage {
        object-fit: cover;
        width: 120px;
        height: 120px;
        border-radius: 120px;
        border: 1px solid map-get($colors, "bordering-gray");
      }

      .nameSection {
        align-items: flex-start;
        margin-left: 30px;
        display: flex;
        flex-direction: column;

        .rateSection {
          color: map-get($colors, "black");
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-weight: 500;

          .rateIcon {
            margin-right: 3px;
          }

          .distance {
            justify-content: center;
            display: flex;
            align-items: center;
            justify-items: center;
            color: map-get($colors, "secondary");
            font-weight: 400;
          }

          .fa-circle {
            margin: 0 10px;
            font-size: 3.5px;
          }
        }

        .shopName {
          font-weight: bold;
        }

        .subscribeButton {
          cursor: pointer;
          margin-top: 8px;
          width: 145px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: 4px;
          border: 1px solid map-get($colors, "bordering-gray");
          color: map-get($colors, "black");
          background-color: white;

          img {
            height: 18px;
          }
        }

        .subscribed {
          background-color: map-get($colors, "gray");
        }
      }
    }

    .rightSection {
      width: 50%;
      height: 100%;
      flex: 1;

      .shopDescription {
        a {
          color: map-get($colors, "black");
          text-decoration: underline;
        }

        #truncateShopDescription {
          width: 100%;
          float: left;
          line-height: 20px;
          height: 0;
          overflow: hidden;

          &.dotdotdot-ready {
            height: auto;
          }
        }
      }

      .lineBorder {
      }

      .fontStyle {
        color: map-get($colors, "secondary");
      }

      .bottomRow {
        border-top: 1px solid map-get($colors, "gray");
        margin-top: 15px;
        padding-top: 15px;

        .orderInfo {
          display: flex;
          flex-direction: row;

          .titleBox {
            color: map-get($colors, "secondary");
            font-weight: 400;
            padding-right: 15px;
          }

          &:nth-child(2) {
            border-left: 1px solid map-get($colors, "gray");
            border-right: 1px solid map-get($colors, "gray");
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #HeaderContainer {
    padding: 20px 0;

    .container {
      .leftSection {
        width: 100%;
      }

      .rightSection {
        width: 100%;

        .lineBorder {
          border-top: 1px solid map-get($colors, "gray");
          border-bottom: 1px solid map-get($colors, "gray");
          padding-top: 10px;
          padding-bottom: 10px;
          margin: 10px 0;
        }

        .locationInfo {
          padding-top: 10px;
        }

        .paymentInfo {
          padding-top: 10px;
        }

        .bottomRow {
          .orderInfo {
            justify-content: space-between;

            &:nth-child(2) {
              border-left: 0;
              border-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>

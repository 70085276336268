<template>
  <div class="shopContainer" id="ShopView">
    <HeaderShopView
        :shop="shopProfile"
        @toggleSubscribe="toggleSubscribe"
        :subscribed="subscribed"
    />
    <div class="container">
      <products-section
          v-if="featuredProducts.length > 0"
          type="featuredProducts"
          :products="featuredProducts"
          :title="$t('shops.featured_products')"
          :subtitle="$t('shops.check_featured')"
      />
      <products-section
          v-if="allProducts.length > 0"
          type="allProducts"
          :products="allProducts"
          :title="$t('shops.all_product')"
          :subtitle="$t('shops.view_all')"
      />
    </div>
  </div>
</template>

<script>
import HeaderShopView from "@/views/Shops/partials/HeaderShopView";
import ProductsSection from "@/views/Home/partials/ProductsSection";
import {mapActions, mapGetters} from "vuex";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";


export default {
  name: "ShopShow",
  components: {HeaderShopView, ProductsSection},
  setup(){
    const route = useRoute();
    const {t} = useI18n() // use as global scope
    useMeta({
      title: route.query.title ?? t("shops.show.page_title"),
    })
  },
  data() {
    return {
      loading: false,
      shopProfile: {},
      subscribed: null,
      featuredProducts: [],
      allProducts: []
    };
  },
  methods: {
    ...mapActions(["setErrors", "getShopProfile"]),

    async toggleSubscribe() {
      return new Promise((resolve, reject) => {
        window.axios
            .post(
                window.Routes.getRoute("shop_follow_toggle", {
                  shop_id: this.shop.id
                })
            )
            .then(
                response => {
                  resolve(response.data.data);
                  this.subscribed = !this.subscribed;
                },
                error => {
                  this.setErrors(error.response.data.errors);
                  reject(error.response.data.errors);
                }
            );
      });
    },
    async getFeaturedProducts() {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("shop_featured_products", {
          shop_id: this.$route.params.id,
          paginate: 6,
          page: 1
        });
        window.axios.get(url, {
          params: prepareGuestAddressParams(),
        }).then(
            response => {
              this.featuredProducts = response.data.data;
              resolve(response.data);
            },
            error => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
        );
      });
    },
    async getAllProducts() {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("shop_all_products", {
          shop_id: this.$route.params.id,
          paginate: 6,
          page: 1
        });
        window.axios.get(url, {
          params: prepareGuestAddressParams(),
        }).then(
            response => {
              this.allProducts = response.data.data;
              resolve(response.data);
            },
            error => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
        );
      });
    },
    getShop() {
      this.getShopProfile({shopId: this.$route.params.id}).catch(() => {
        this.$router.push('/');
      });
    }
  },
  computed: {
    shop_id: function () {
      return parseInt(this.$route.params.id);
    },
    ...mapGetters(["shop"])
  },
  watch: {
    shop() {
      this.shopProfile = this.shop;
      this.subscribed = this.shop.subscribed;
    }
  },
  created() {
    this.getShop();
    this.getFeaturedProducts();
    this.getAllProducts();
  }
};
</script>

<style lang="scss" scoped>
#ShopView {
  width: 100%;
  float: left;
}
</style>
